import { createAsyncThunk } from "@reduxjs/toolkit";
import {createApiInstance} from "api/httpCommon";
import { InferType } from "yup";
import { AppsRegisterClient, RegisterVisit, SurveyInputs } from "schemas/formSchema";

type RegisterClient = InferType<typeof AppsRegisterClient>;
type RegisterVisit = InferType<typeof RegisterVisit>;
type SurveyData = { client: string; trace: string; };
type Survey = InferType<typeof SurveyInputs>;

const apiV2 = createApiInstance('V2');

export const ADDNEWCLIENT = createAsyncThunk(
    "store/addClient",
    async (newClient:RegisterClient, { rejectWithValue }) => {
      try {
        const response = await apiV2.post(`/store/clients/register`, newClient);
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  )

  export const REGISTERVISIT = createAsyncThunk(
    "store/registerVisit",
    async (newVisit:RegisterVisit, { rejectWithValue }) => {
      try {
        const response = await apiV2.post(`/store/visit/register`, newVisit);
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  )

  export const GET_STORE_LIST = createAsyncThunk(
    "store/list",
    async (client:string, { rejectWithValue }) => {
      try {
        const response = await apiV2.get(`/store/${client}`);
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  )

  export const GET_SURVEY_DATA = createAsyncThunk(
    "store/survey",
    async (survey:SurveyData, { rejectWithValue }) => {
      try {
        const response = await apiV2.get(`/store/survey/${survey.client}/trace/${survey.trace}`);
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  )

  export const SAVESURVEY = createAsyncThunk(
    "store/savesurvey",
    async (surveyData:Survey, { rejectWithValue }) => {
      try {
        const response = await apiV2.post(`/store/survey`, surveyData);
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  )